<template>
  <v-app>

    <v-main>

      <component :is="comp" keep-alive />
            <SheetRecapPoint  />

   <DialogPoint />
     </v-main>
      <v-bottom-navigation
    app
    fixed
    shift
    
 
      v-model="value"
    >
     <div ref="container">

    </div>
  <v-btn value="Points">
        <span class="text-capitalize">{{ $t('navbar.points') }}</span>

        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
  <v-btn value="RankingAndPrizes">
    <span class="text-capitalize">{{ $t('navbar.rankingsAndPrizes') }}</span>

        <v-icon>mdi-podium</v-icon>
  </v-btn>
      <v-btn value="Rules">
        <span class="text-capitalize">{{ $t('navbar.rules') }}</span>

        <v-icon>mdi-information-outline</v-icon>
      </v-btn>

      <v-btn value="Settings">
        <span class="text-capitalize">{{ $t('navbar.settings') }}</span>

        <v-icon>mdi-cogs</v-icon>
      </v-btn>
  
         <v-btn value="Account">
        <span class="text-capitalize">{{ $t('navbar.auth') }}</span>

        <v-icon>mdi-account</v-icon>


      </v-btn>
     
    </v-bottom-navigation>`
    <v-bottom-sheet v-model="sheetLogin" >
      <v-card>
        <v-card-title>
          Authentification
        </v-card-title>
        <v-card-text class="text-center">
          <p v-if="!resetPwd">{{ $t('ui.disclaimerAuth') }}</p>
          <template v-if="!useMailPwdAuth">
            <form @submit.prevent="resetPasswordAction">
             <v-btn class="ma-3" @click="signin('google');"> <v-icon class="formIcon">mdi-google</v-icon> Connexion Google </v-btn>
            <v-btn class="ma-3" @click="signin('facebook');"><v-icon class="formIcon">mdi-facebook</v-icon> Connexion Facebook </v-btn>
            <v-btn small class="ma-3" @click="useMailPwdAuth = true;"><v-icon class="formIcon">mdi-email</v-icon> Connexion par email et mot de passe </v-btn>
            </form>
          </template>
           <template v-else> 
             <template v-if="!resetPwd">
                <v-alert type="warning" v-if="error" >
               {{ $t('authError.'+error.code+'') }}
             </v-alert>

              <v-alert type="info" v-if="sendEmailReset">
              {{ $t('ui.sendEmailReset')}}
              </v-alert>
             <form @submit.prevent="loginEmailPwd">
             <v-text-field outlined v-model="form.email"  :placeholder="$t('ui.email')" />

              <v-text-field outlined v-model="form.password" type="password" :placeholder="$t('ui.password')"/>
              <v-btn class="mb-4" :disabled="!formAuthValid" type="submit" :loading="loginInProgress" block color="primary">{{ $t('ui.submitAuth')}} </v-btn>
              <v-btn text @click="useMailPwdAuth = false;">{{ $t('ui.back')}} </v-btn>
                            <v-btn text @click="resetPwd = true;">{{ $t('ui.resetPwd')}} </v-btn>

             </form>

             </template>
            <template v-else>
              <p>{{ $t('ui.resetPasswordText') }}</p>
               <form @submit.prevent="resetPasswordAction">
              <v-text-field outlined v-model="form.email"  :placeholder="$t('ui.email')" />
              <v-btn class="mb-4" :disabled="!formResetValid" type="submit"  block color="primary">{{ $t('ui.resetAuth')}} </v-btn>
          <v-btn text @click="resetPwd = false;">{{ $t('ui.back')}} </v-btn>
               </form>
            </template>
           </template>
        </v-card-text>
      </v-card>
          

    </v-bottom-sheet>
    <Game />
   <v-snackbar v-model="snack">
     {{ $t('ui.yourNewScore')}} {{ $store.getters.user.score }} points
   </v-snackbar>
  
   <v-snackbar v-model="snackWelcome">
     {{ $t('ui.welcome') }} 
   </v-snackbar>
     <v-snackbar v-model="snackLogged">
     {{ $t('ui.nowLogged') }} 
   </v-snackbar>
  </v-app>
</template>

<script>
import SheetRecapPoint from './components/Sheet.vue';
import Settings from './components/Settings';
import Points from './components/Points';
import DialogPoint from './components/Points/Dialog';
import Rules from './components/rules.vue';
import Account from './components/Account.vue';
import Vue from 'vue';
import { firebase } from '@firebase/app';
import '@firebase/auth';
import Game from './components/Points/Game.vue';
import RankingAndPrizes from './components/rankingsAndPrizes.vue';
export default {
  name: 'App',

  components: {
    Points, SheetRecapPoint, Settings, DialogPoint, Rules, Account, Game, RankingAndPrizes
  },
  methods: {
    resetPasswordAction() {
            firebase.auth().sendPasswordResetEmail(this.form.email)
        .then(() => {
          this.resetPwd = false;
          this.sendEmailReset = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    signin(mode) {  
      this.sendEmailReset = false;
      let provider;
            switch(mode) {
                case 'google':
                    provider = new firebase.auth.GoogleAuthProvider();
                    break;

                case 'facebook':
                    provider = new firebase.auth.FacebookAuthProvider();
                    break;
  

            }
            
            
            firebase.auth()
  .signInWithPopup(provider).then((result) => {
             
            if(result.additionalUserInfo.isNewUser) {
               let db = firebase.firestore();
               let playerRef = db.collection('players').doc(result.user.uid);
                playerRef.set({nickname: result.user.displayName}).then(() => {
                  this.$root.$emit('welcome');
                  this.sheetLogin = false;
                });
            } else {
              this.sheetLogin = false;
            }
            
            }).catch((error) => {
                alert('Désolé une erreur est survenue lors de l\'authentification');

                alert(this.$t('authError.'+error.code));

            });
    },
    loginEmailPwd() {
      this.error = null;
      this.sendEmailReset = false;
      this.loginInProgress = true;
      firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password)
  .then(() => {
    // Signed in 
    this.form = {email: '', password: ''};
    this.useMailPwdAuth = false;
    this.sheetLogin = false;
    this.$root.$emit('welcome');
    this.value = 'Points';
    //var user = userCredential.user;
    // ...
  })
  .catch((error) => {
    console.error(error);
    if (error.code == 'auth/email-already-in-use') {
         firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)
  .then(() => {
     this.form = {email: '', password: ''};
    this.useMailPwdAuth = false;
    this.sheetLogin = false;
    this.value = 'Points';
  })
  .catch((error) => {
    this.error = error;
  });
    } else {
       this.error = error;
    }
   
   
  }).finally(() => {
     this.loginInProgress = false;
  })
 

    },
    openDialog(data) {
      
      let DialogCtrl = Vue.extend(DialogPoint);
      let instance = null;
  
      instance = new DialogCtrl({ propsData: {data: data}});
      //instance.$root = this.$root;
      //instance.$i18n = this.$i18n;
      instance.$mount();
      instance.$slots.default = [];

      this.$refs.container.appendChild(instance.$el);
    },
    openSheet(data){
      console.log(data);
    }
  },
  computed: {
    formResetValid() {
       return  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email);
    },
    formAuthValid() {
      return this.form.password.length > 5 && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email);
    },
    comp() {
      return this.value;
    }
  },
  mounted() {
    //this.$root.$on('open-dialog-point', this.openDialog);
    this.$root.$on('login', (p) => {
      if (p && p.redirect) {
        this.redirect = p.redirect;
      }
      this.sheetLogin = true;
      
    });

    this.$root.$on('welcome', () => {
      this.snackWelcome = true;
    })

      this.$root.$on('newScore', () => {
        this.snack = true;
    });
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          this.$store.dispatch('setUserLogged', true);
          this.$store.dispatch('setUserData', user);
          let db = firebase.firestore();
          db.collection('validations').where('player', '==', user.uid).get().then((x) => {
            this.$store.dispatch('setValidations', x.docs.map((d) => d.data()));
            this.$store.dispatch('computeScore');

            if (this.redirect) {
              this.$root.$emit(this.redirect.action, this.redirect.props.point);
            }
            this.snackLogged = true;
          }).catch((e) => {
            console.log(e);
            console.log('catch');
          });
          
          
        } else {
          this.$store.dispatch('setSignOut');
        }
      });

  },
  data: () => ({
    sheetLogin: false,
    redirect: null,
    sendEmailReset: false,
    form: {email: '', password: ''},
    snackWelcome: false,
    resetPwd: false,
    snackLogged: false,
     value: "Points",
     error: null,
     loginInProgress: false,
     snack: false,
     useMailPwdAuth: false
  }),
};
</script>
<style>
.bar {
  background-color: rgb(27, 27, 27);
}
#appTitle {
  font-family: 'Heebo', sans-serif;
  font-size: xx-large;
}
.formIcon {
  margin-right: 10px;
}
</style>
