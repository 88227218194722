<template>
    <v-container>
    
            <v-list v-if="points.length > 0">
                <template v-for="(item, x) of points">
                    <v-list-item :key="'point_'+x" class="ma-0" @click="$root.$emit('open-small-dialog', item);">
                        <v-list-item-action class="mr-0">
                            <v-list-item-avatar>
                                <v-icon>{{ icon(item) }}</v-icon>
                            </v-list-item-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.name.fr }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.location.address.city }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-avatar>
                                <v-icon x-large>mdi-chevron-right</v-icon>
                            </v-list-item-avatar>
                        </v-list-item-action>
                    </v-list-item> 
                    <v-divider :key="'divider_'+item"/>
                </template>
            </v-list>
            
        
            <template v-else>
                <v-alert outlined type="info">
                    {{ $t('ui.no_points')}}
                </v-alert>
            </template>

    </v-container>
</template>
<script>
export default {
    props: ['points'],
    data: () => ({
       
    }),
    mounted() {
        console.log(this.points);
    },
    methods: {
        icon(p) {
            let i;
                            console.log(p.types);

            switch(true) {
                case p.types.includes('Bakery'):
                    i = 'mdi-baguette';
                    break;
                case p.types.includes('FastFoodRestaurant'):
                    i = 'mdi-food';
                    break;
           
                case p.types.includes('BarOrPub'):
                    i = 'mdi-glass-mug-variant';
                    break;
                case p.types.includes('ParkAndGarden'):
                    i = 'mdi-pine-tree';
                    break;
                case p.types.includes('Hotel'):
                    i = 'mdi-bed';
                    break;
                case p.types.includes('BoutiqueOrLocalShop'):
                    i = 'mdi-store';
                    break;   

                case p.types.includes('Store'):
                    i = 'mdi-store';
                    break;
        
                case p.types.includes('Accomodation'):
                    i = 'mdi-bed';
                    break
                
                case p.types.includes('Cinema'):
                    i = 'mdi-theater';
                    break;

                case p.types.includes('FoodEstablishment'):
                    i= 'mdi-silverware-fork-knife';
                    break;

                case p.types.includes('SportsAndLeisureplace'):
                    i = 'mdi-ferris-wheel';
                    break;

                case  p.types.includes('BistroorWinebar'):
                    i = 'mdi-glass-wine';
                    break;

                case p.types.includes('Winery'):
                    i = 'mdi-fruit-grapes';
                    break;
                
                case p.types.includes('ShoppingCentreAndGallery'):
                    i = 'mdi-basket';
                    break;

                case p.types.includes('MovieTheater'):
                    i = 'mdi-theater';
                    break;
                
                case p.types.includes('Restaurant'):
                    i = 'mdi-table-chair';
                    break;
                
                case p.types.includes('Parkandgarden'):
                    i = 'mdi-pine-tree';
                    break;

                case p.types.includes('Culturalsite'):
                    i = 'mdi-palette-outline';
                    break;
                
                case  p.types.includes('Nightclub'):
                    i = 'mdi-music';
                    break;

                case p.types.includes('Event'):
                    i = 'mdi-calendar';
                    break;

                case p.types.includes('Museum'):
                    i = 'mdi-museum';
                    break;
                
                default:
                    i = 'mdi-eye';

            }
            return i;
        }
    }
}
</script>