<template>
     <v-bottom-sheet v-model="sheet" >
       <v-card rounded v-if="point">
         <v-card-title class="flex-nowrap">
           <h3 class="text-truncate">{{ name.fr }}</h3>
           <v-spacer />
            <v-btn icon @click="sheet = false;"><v-icon>mdi-close</v-icon></v-btn>
         </v-card-title>
        <v-card-text>
          
          <p v-if="point.description.fr" class="text-justify">
            {{ point.description.fr}}
          </p>
          <p>
            <template v-for="(t,i) of  typesComputed">
              <v-chip class="ma-1" small :key="'chip_'+i" v-if="iconType(t)" >
               <v-avatar left >
                <v-icon>{{ iconType(t) }}</v-icon>
      </v-avatar>
                {{ $t('types.'+ t) }} 
              
              </v-chip>
            </template>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="$store.getters.user.logged ? $root.$emit('game', point): redirect(point) "><v-icon class="mr-2">mdi-play</v-icon>Jouer</v-btn>
  <v-spacer />
          <v-btn text @click="$root.$emit('open-dialog-point', point)"><v-icon class="mr-2">mdi-text</v-icon>Détails</v-btn>
        </v-card-actions>
       </v-card>
            

   </v-bottom-sheet>
</template>
<script>
export default {
  data: () => ({
    sheet: false,
    types: ['Store','Hotel', 'Accomodation', 'Lieu de loisirs et de sports' ],
    point: null
  }),
   created: function() {
    this.$root.$on('open-small-dialog', this.openSheet);
  },
  computed: {
    name: function() {
      return typeof this.point.name == 'string' ? JSON.parse(this.point.name) : this.point.name;
    },
    typesComputed: function() {
     
      return typeof this.point.types == 'string' ? JSON.parse(this.point.types) : this.point.types;
    }
  },
  methods: {
    redirect(point) {
       this.$root.$emit('login', {'redirect': {'action': 'game', 'props': {'point': point}} })
    },
    notAvailable() {
      alert(this.$t('ui.available_soon'));
    },
    iconType: function(type) {
      let i = null;
      switch(type.toLowerCase()) {
     
        case 'store':
          i = 'mdi-store';
          break;
        
        case 'accomodation':
          i = 'mdi-bed';
          break
        
        case 'cinema':
          i = 'mdi-theater';
          break;

        case 'foodestablishment':
          i= 'mdi-silverware-fork-knife';
          break;

        case 'sportsandleisureplace':
          i = 'mdi-ferris-wheel';
          break;

        case 'bistroorwinebar':
          i = 'mdi-glass-wine';
          break;

        case 'winery':
          i = 'mdi-fruit-grapes';
          break;
        
        case 'shoppingcentreandgallery':
          i = 'mdi-basket';
          break;

        case 'barorpub':
          i = 'mdi-glass-mug';
          break;
        
        case 'restaurant':
          i = 'mdi-table-chair';
          break;
        
        case 'parkandgarden':
          i = 'mdi-pine-tree';
          break;

        case 'culturalsite':
          i = 'mdi-palette-outline';
          break;
        
        case 'nightclub':
          i = 'mdi-music';
          break;

        case 'event':
          i = 'mdi-calendar';
          break;

        case 'museum':
         i = 'mdi-museum';
         break;


      }

      return i;
    },
    icon(v) {
      console.log(v);
      let i = 'mdi-eye';
      
      return i;
    },
    openSheet(data){
        this.point = data;
    
        this.sheet = true;
     
    }
  }
}
</script>