<template>
    <v-container>
        <h2 class="text-h3 text-capitalize">{{ $t('settings.title')}}</h2>
        <v-subheader class="text-h5 text-capitalize">{{ $t('settings.languages.title')}}</v-subheader>
        <v-select v-model="locale" item-text="value" item-value="key" outlined :items="locales">

        </v-select>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        locales: [{key: "fr", value: "Français"}, {key:"en", value: "English"}]
    }),
    computed: {
        locale: {
            get: function() {
                return this.$i18n.locale;
            },
            set: function(v) {
                this.$i18n.locale = v;
            }
        }
    }
}
</script>