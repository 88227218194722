import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store =  new Vuex.Store({
    state: {
        user: {logged: false, data: null, score: 0, validations: []}
    },
    getters: {
        user: state => state.user
    },
    mutations: {
        initialiseStore(state) {
            if(localStorage.getItem('store')) {
                    // Replace the state object with the stored item
                    this.replaceState(
                      Object.assign(state, JSON.parse(localStorage.getItem('store')))
                    );
            }
        },
        setUserLogged(state, data) {
            state.user.logged = data;
        },
        setUserData(state, data) {
            state.user.data = data;
        },
        updateScore(state, data) {
            console.log(data);
            state.user.score += data;
        },
        setScore(state, data) {
            state.user.score = data;
        },
        addValidation(state, data) {
            console.log(state);
            console.log(state.user);
            
            state.user.validations.push(data);
        },
        setValidations(state, data) {
            state.user.validations = data;
        },
        computeScore(state){
            state.user.score = state.user.validations.reduce( (a, v) => a + v.value ,0);
        }
    },
    actions: {
        computeScore({commit}){ 
            commit('computeScore'); 
        },
        setScore({commit}, data) {
            commit('setScore', data);
        },
        addValidation({commit}, data) {
            commit('addValidation', data);
            commit('updateScore', data.value);
        },
        setValidations({commit}, data) {
            commit('setValidations', data);
        },
        updateScore({commit}, data) {
            commit('updateScore', data);
        },
        setSignOut({commit}) {
            commit('setUserLogged', false);
            commit('setUserData', null);
        },
        setUserLogged({commit}, data){
            commit('setUserLogged', data);
        },
        setUserData({commit}, data){
            commit('setUserData', data);
        }

    }
  })


store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});
export default store
