<template>
    <p>
         <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="text" v-if="loading"
    ></v-skeleton-loader>
    <template v-else-if="agent">
<span><a target="_blank" :href="agent.websites[0] || agent.id">{{ agent.name }}</a></span>
    </template>
    </p>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
export default {
    props: ['data'],
    data: () => ({
        loading: false,
        agent: null
    }),
    mounted() {
        this.loading = true;
        let db = firebase.firestore();
        firebase.firestore();
            let collection = db.collection('organizations');
            collection.doc(this.data.ref).get().then((d) => {
                console.log(d);
                if (d.exists){
                    this.agent = d.data();
                    console.log(d.data());
                }
            }).finally(() => {
                this.loading = false;
            })
        console.log(this.data);
    }
}
</script>