<template>
    <v-container class="ma-0 pa-0 fill-height">
        <v-tabs   id="tabPoints" v-model="tabs"   :style="{'height': '100%'}"  fixed-tabs  >
            <v-tab href="#tab-map"><v-icon>mdi-map</v-icon></v-tab>
            <v-tab href="#tab-list"><v-icon>mdi-view-list</v-icon></v-tab>
  <v-tabs-items :touchless="true" id="tabs" v-model="tabs"  >
    <v-tab-item value="tab-map" key="2" style="height: 100%; width: 100%;">
        <div style="height: 100%; width: 100%;">
           
                <Map  :getColor="getColor" :tabs="tabs"/>
  
            
     </div>
 </v-tab-item>
 <v-tab-item value="tab-list" key="1">
     <List :getColor="getColor" :points="points.slice(i, i + 100)" />
 </v-tab-item>

  </v-tabs-items>
    </v-tabs>

    </v-container>
</template>
<script>
import List from './list';
import Map from './map';
export default {
    data: () => ({
    tabs: null,
    i: 0,
    focus: false,
    points: []
    }),
    components: { List, Map },
    methods: {
        getColor: function(v) {
            
           
            let c;
            
            
                if (v.includes('FoodEstablishment')) {
                    c = '#F1201D';
                } else if (v.includes('Accomodation')) {
                    c = '#0F0A70';
                } else if (v.includes('EntertainmentAndEvent') ||  v.includes('Event')) {
                    c = '#911BC8';
                } else if (v.includes('CulturalSite')) {
                    c = '#7069F1';
                }else {
                     c = '#0AA01A';
                }
                    
             


            return c;
        }
    },
    mounted() {
        console.log(this.$router);
        console.log(this.$route);
        console.log(this.region);
        this.$root.$on('pointsUpdate', (data) => {
            this.points = data;
        })
    } 
}
</script>
<style>
#tabs {
    height: 100%;
    width: calc(100% - 0px);
}
#tabPoints > .v-tabs-bar {
    position: sticky;
    top: 0;
    z-index: 2;
}
</style>