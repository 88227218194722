import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify';
import i18n  from './i18n';
import store from './store'
import firebase  from 'firebase/app';
import 'firebase/analytics';

Vue.config.productionTip = false
var firebaseConfig = {
  apiKey: "AIzaSyBgW1MYSndZiWaCpgqOMiwPLyy8igcxdwk",
  authDomain: "gofrance-7eb17.firebaseapp.com",
  databaseURL: "https://gofrance-7eb17.firebaseio.com",
  projectId: "gofrance-7eb17",
  storageBucket: "gofrance-7eb17.appspot.com",
  messagingSenderId: "448361569742",
  appId: "1:448361569742:web:1251576e085db9d9"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  router,
  i18n,
  vuetify,
  store,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  render: h => h(App)
}).$mount('#app')
