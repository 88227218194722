<template>
    <v-container>
        <h1 class="title">Gestion de mon compte</h1>
        <v-divider />

        <template v-if="$store.getters.user && $store.getters.user.logged">
            <v-subheader class="text-capitalize">{{ $t('ui.account') }}</v-subheader>
                <template>  
                    <v-list two-line>

                        <v-list-item>
                            <v-list-item-avatar>
                                   <v-avatar size="30">
                    <img :src="$store.getters.user.data.photoURL"/>
                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ $store.getters.user.data.displayName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ $store.getters.user.data.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="logout" ><v-icon>mdi-exit-to-app</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <v-row :style="{'align-items': 'baseline'}">
                        <v-col cols="10">
                            <v-text-field :label="$t('ui.editNickname')" :disabled="!editing" v-model="nickname" outlined :placeholder="$t('ui.nickname')">
                    </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-btn icon elevation="2"  v-if="editing" @click.prevent="saveNickname"><v-icon>mdi-content-save</v-icon></v-btn>
                             <v-btn icon elevation="2" @click="() => { this.editing = true;}" v-else><v-icon>mdi-pencil</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    
                    <p>
             
                    
                   
                    </p>
                </template>
                 <v-subheader class="text-capitalize">{{ $t('ui.score') }}</v-subheader>
           <h3>{{ $store.getters.user.score }} points</h3>
              <v-subheader class="text-capitalize">{{ $t('ui.validations') }}</v-subheader>
              <v-list two-line>
                  <template v-for="(v,i) of sortedValidations">
                  <v-list-item  :key="'validation_'+i" @click="$root.$emit('open-dialog-point', v.point)">
                      <v-list-item-content>
                          <v-list-item-title v-if="v.name">{{ v.name.fr }}</v-list-item-title>
                          <v-list-item-title v-else>N/A</v-list-item-title>
                          <v-list-item-subtitle v-if="v.date.toDate">{{ firestoreTStoString(v.date.toDate()) }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-else>N/A</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                          <v-badge :content="'+ '+v.value"></v-badge>
                      </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="'line_'+i" />
                  </template>
              </v-list>
           
        </template>
        <template v-else>
            <v-btn class="mt-2" block  @click="signin">{{ $t('account.signin') }}</v-btn>
        </template>

        
        
        
    </v-container>
</template>
<script>
import { firebase } from '@firebase/app';

export default {
    data: () => ({
        nickname: null,
        editing: false
    }),
computed: {
    sortedValidations() {
        return this.$store.getters.user.validations.slice().sort((a, b) => {
            if (a.date.toMillis && b.date.toMillis) {
                 return a.date.toMillis() < b.date.toMillis() ? 1 : -1;
            } else {
                return - 1;
            }
           
        });
    }
},
mounted() {
    if (this.$store.getters.user.logged) {
      let db = firebase.firestore();
      db.collection('players').doc(this.$store.getters.user.data.uid).get().then((d) => {
          if(d.exists) {
              this.nickname = d.data().nickname;
          }
      })
    }
},
methods: {
    saveNickname: function() {
        let db = firebase.firestore();
      db.collection('players').doc(this.$store.getters.user.data.uid).set({nickname: this.nickname}, {merge: true}).then(() => {
          this.editing = false;
      });
    },
    firestoreTStoString: function(v) {
        let d = new Date(v);
        console.log(v);
        return d.toLocaleDateString() +' - '+ d.toLocaleTimeString();
    },
    signout: function() {
        this.$store.dispatch('setSignOut');
    },
    signin: function() {
        this.$root.$emit('login');
    },
    logout: function() {
        if(window.confirm(this.$t('ui.logoutConfirm'))){
                firebase.auth().signOut().then(() => {
                    this.$store.dispatch('setSignOut');
                }).catch((error) => {
                    alert(error);
                });
        }
    }
}
}
</script>