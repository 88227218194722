<template>
    <v-container class="ma-0 pa-0">
         <v-tabs
    fixed-tabs
    v-model="tabs"
    :style="{'position':'fixed', 'z-index': 2000}"
  >
    <v-tab>
      {{ $t('ui.ranking') }}
    </v-tab>
    <v-tab>
       {{ $t('ui.prizes') }}
    </v-tab>

         </v-tabs>
          <v-tabs-items v-model="tabs" :style="{'padding-top': '45px', 'padding-bottom': '45px'}">
                 <v-tab-item key="ranking" class="text-center">
                        <v-progress-circular class="mt-4" v-if="loading" indeterminate size="45" />

                    <template v-else v-for="(r,i) of rankingSorted">
                        <v-card   rounded :key="'card_'+i" color="white" min-height="35" class="rounded-pill" :style="{'margin-left': '5px', 'margin-right': '5px', 'margin-top': '30px'}">
                            <v-row class="ml-2 mr-2">
                                <v-col cols="2" class="text-left">
                                    {{ i + 1}}
                                </v-col>
                                <v-col cols="6" class="text-center text-truncate" >
                                    <span class="text-uppercase">{{ r.nickname }}</span>
                                </v-col>
                                <v-col class="text-right" cols="4">
                                   <span> {{ r.score }} points</span>
                                </v-col>
                            </v-row>
                        </v-card>

                    </template>
 <v-speed-dial
 v-if="false"
      v-model="fab"
      fixed fab bottom right
      direction="left"
    
      :style="{'bottom': '66px'}"
    >
     <template v-slot:activator>
    <v-btn fab  color="primary" > <v-icon v-if="fab">
            mdi-close
          </v-icon><v-icon v-else>mdi-magnify</v-icon></v-btn>
     </template>
     <v-text-field full-width color="white" outlined v-model="search" />
 </v-speed-dial>
                 </v-tab-item>
                 <v-tab-item key="prizes">
<p>{{ $t('ui.soon') }}</p>
                 </v-tab-item>
          </v-tabs-items>
    </v-container>
    
</template>
<script>
import { firebase } from '@firebase/app';

export default {
    data: () => ({
        tabs: "ranking",
        fab: false,
        rankings: [],
        search: '',
        loading: false
    }),
    computed: {
        rankingSorted: function() {


            return this.rankings.slice().sort((a, b) => {
                return a.score > b.score ? -1 : 1;
            })
        }
    },
    methods: {
        loadRanking: function() {
            console.log('loadRanking');
            let db = firebase.firestore();
            this.loading = true;
            db.collection('scores').get().then((d) => {
                this.rankings = d.docs.map((x) => {
                    return x.data();
                })
            }).finally(() => {
                this.loading = false;
            })

        }
    },
    watch: {
        tabs: function(v) {
            console.log(v);
                if (v == 0) {
                    this.loadRanking();
                }
        }
    }
}
</script>