<template>
    <v-bottom-sheet v-model="dialog" v-if="data">
            <v-toolbar color="primary" dark>
               <v-toolbar-title>Jouez et gagnez <span v-if="data && !data.values">des</span><span v-else>{{ data.values.gps }} </span> points</v-toolbar-title>
                <v-spacer />
               <v-toolbar-items>
                  
                   <v-btn @click="dialog = false; loaded = false;" icon><v-icon>mdi-close</v-icon></v-btn>
               </v-toolbar-items>
            </v-toolbar>
            <v-card min-height="300" class="justify-center text-center">
                <v-card-text v-if="data && data.values" >
                    <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
                    <p>Validez votre position GPS et remportez  {{ data.values.gps }} points</p>

                    <!--<v-btn  rounded width="80%" @touchend.native.stop="touching = false;"
  @touchstart.native="touching = true;">
  <span v-if="!touching" :style="{height: '10px'}">
      Maintenez enfoncer pour valider
  </span>
  <span v-else :style="{height: '10px', 'width': '100%'}">
      <v-progress-linear height="15" color="primary" :value="progress" />
  </span>
  
  </v-btn>
  {{ touching }} -->


  <v-btn v-if="!$store.getters.user.validations.find(v => v.point == data.id)" @click="checkPos" block :loading="validating">{{ $t('ui.point_validate') }}</v-btn>
  <v-btn v-else block disabled>{{ $t('ui.point_alreadyChecked') }}</v-btn>
                </v-card-text>
                <template v-else>
                    <v-progress-circular  class="mt-5" indeterminate size="45"/>
                </template>
            </v-card>
    </v-bottom-sheet>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
import { Geolocation } from '@capacitor/geolocation';
require("firebase/functions");

import "mapbox-gl/dist/mapbox-gl.css";
export default {
    data: () => ({
        dialog: false,
        progress: 0,
        overrideValidation: false,
        data: null, 
        accessToken:"pk.eyJ1Ijoic2ltb241MSIsImEiOiJjamg4dGUyZXkwNmxuM2NvNmFxN2p5cnpmIn0.G4Igc5WvjFjuIQ6UcuxLOQ", // your access token. Needed if you using Mapbox maps
         mapStyle: "mapbox://styles/mapbox/streets-v11",
        loaded: false,
        touching: false,
        validating: false,
        loading: false,
        interval: null
    }),

    created: function() {
        console.log('created');
        this.$root.$on('game', (data) => {
            console.log(data);
            this.dialog = true;
            this.data = data;
        });
    },
    watch: {
        touching: function(v) {
            if (v) {
                this.interval = setInterval(() => {
                    window.navigator.vibrate(100);
                    this.progress += 10;
                }, 500);
                
            } else {
                this.progress = 0;
                clearInterval(this.interval);
            }
        },
        data: function(v) {
            if(v && v.id && !this.loaded) {
                let db = firebase.firestore();
            let collection = db.collection('points');
            this.loading = true;
            collection.doc(v.id).get().then((d) => {
               
                if (d.exists){
                    this.loading = false;
                    this.data = {...d.data(),id: d.id };
                    this.loaded = true;
                }
            })
            }
        }
    },
    methods: {
        getDistanceFromLatLonInKm: function(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
  var dLon = this.deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
},

    deg2rad: function(deg) {
  return deg * (Math.PI/180)
},
        checkPos: async function() {
            let notDone = await this.checkNotDone();
            if (notDone){
                this.validating = true;
            try {
                const coordinates = await Geolocation.getCurrentPosition();
                
                let dist = this.getDistanceFromLatLonInKm(coordinates.coords.latitude, coordinates.coords.longitude,this.data.location.geolocation.lat, this.data.location.geolocation.lng);
                dist = dist * 1000; // en mètres
                let range = this.data.methods.find(m => m.type == 'gps').range || 100;
                if (this.overrideValidation || dist < range) {
                    
                    this.validating = false;
                    //let d = {value: }
                    let o = {name: this.data.name, value: this.data.values.gps, type: 'gps', geolocation:  {lat:coordinates.coords.latitude, lng:  coordinates.coords.longitude}, player: this.$store.getters.user.data.uid, point: this.data.id, date: firebase.firestore.FieldValue.serverTimestamp() };
                     let db = firebase.firestore();
            db.collection('validations').doc().set(o).then(() => {
                this.$store.dispatch('addValidation', o);
                this.$root.$emit('newScore');

                let f = firebase.app().functions('europe-west1');
                
                let computePersonalScore = f.httpsCallable('computePersonalScore');
                    console.log('ici');
                computePersonalScore({}).then((r) => {
                    console.log(r);
                    console.log('B');
                }).catch((e) => {
                    console.error(e);
                    console.log('C');
                })


            }).catch((e) => {
                console.error(e);
                alert(this.$t('ui.sorryError'));
            }).finally(() => {
                this.validating = false;
            });

                } else {
                    this.validating = false;
                    alert('Vous êtes trop éloigné ('+ Number(dist/1000).toFixed(2)+' km) du point.');
                }
        
            
            }catch(e) {
                this.validating = false;
                alert('Une erreur est survenue');
            }


            } else {
                alert(this.$t('ui.alreadyDone'));
            }
            
           
        },
        checkNotDone: async function() {
            let db = firebase.firestore();
            let res = await db.collection('validations').where('player', '==', this.$store.getters.user.data.uid).where('point', '==', this.data.id).get();
            return res.empty;
        },
        touchstart: function() {
            console.log("touch start");
        },
        touchend: function() {
            console.log("touch end");
        },
         load(e) {
            this.map = e.map
         }
    },
    mounted() {
         window.addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.key == "k") {
        this.overrideValidation = !this.overrideValidation;
      }

      
    });
    }
}
</script>