import Vue from "vue";
import VueI18n from "vue-i18n";
import FR from './locales/fr.json';
import EN from './locales/en.json';
Vue.use(VueI18n);


export default new VueI18n({
    locale: "fr",
    fallbackLocale: "fr",
    messages: {
        fr: FR,
        en: EN
    }
  });