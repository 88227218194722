<template>
  <div class="mapbox-container">
    <div class="wrapperCentral">
      <v-btn
        @click="findInArea"
        small
        id="btnRefresh"
        dark
        :loading="loading"
        rounded
        color="green"
        ><v-icon class="mr-2">mdi-magnify</v-icon>{{ $t('ui.findInArea') }}</v-btn
      >
    </div>


    <MglMap
      @zoomend="zoomend"
     
      :zoom="15"
      @load="load"
      :accessToken="accessToken"
      :mapStyle="mapStyle"
    >
    <v-btn @click="showLegend = true;" elevation="5" color="primary" large icon :style="{'position':'absolute', 'bottom': '105px', 'right':'5px'}"><v-icon size="40">mdi-information</v-icon></v-btn>
      <MglGeocoderControl
    language="fr"
    :mapbox="Mapbox"
    countries="fr"
   :placeholder="$t('map.searchTitle')" 
    :accessToken="accessToken"

   />
    </MglMap>
    <v-bottom-sheet v-model="showLegend">
      <v-sheet height="200px">
        <v-subheader>Légende</v-subheader>
         <v-row no-gutters>
        <template v-for="(c,i) of colors">
          <v-col :key="'col_'+i">
          <v-icon :key="'circle_'+i" :color="c.color">mdi-circle</v-icon>
          <span :key="'text_'+i" class="pa-3"> 
            {{ $t('ui.legend.'+c.type+'') }}

          </span>
          </v-col>
           <v-responsive
          v-if="i === 2"
          :key="`width-${i}`"
          width="100%"
        ></v-responsive>
        </template>
         </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import Mapbox from "mapbox-gl";
import { Geolocation } from '@capacitor/geolocation';

import { MglMap  } from "vue-mapbox";
import MglGeocoderControl from "vue-mapbox-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import firebase from "firebase/app";
import "firebase/firestore";
import * as geofirestore from "geofirestore";


export default {
  components: {
    MglMap, MglGeocoderControl
  },
  props: ['tabs', 'getColor'],
  data() {
    return {
      zoom: 0,
      options: {countries: null, mapboxgl: null},
      loading: false,
      firestore: null,
      data: [],
      colors: [
            {'color': '#F1201D', type: 'FoodEstablishment'},
            {'color': '#0F0A70', type: 'Accomodation'},
            {'color': '#911BC8', type: 'EntertainmentAndEvent'},
            {'color': '#7069F1', type: 'CulturalSite'},
            {'color': '#0AA01A', type: 'Other'}

      ],
      showLegend: false,
      source: null,
      geofirestore: null,
      geocollection: null,
      accessToken:
        "pk.eyJ1Ijoic2ltb241MSIsImEiOiJjamg4dGUyZXkwNmxuM2NvNmFxN2p5cnpmIn0.G4Igc5WvjFjuIQ6UcuxLOQ", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mapbox/streets-v11"
    };
  },
  watch: {
    'tabs': function(v) {
      if (v == 'tab-map') {
        setTimeout(() => { this.map.resize(); }, 750);
      }
    }
  },
  methods: {
    open() {
      this.$emit('open-small-dialog', {toto: 'titi'});

    },
    dataToGeojson(data) {
      let g =  { type: "FeatureCollection", features: [] };

      g.features = data.map((f) => {
        console.log(this.getColor(f.types));
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              f.location.geolocation.lng,
              f.location.geolocation.lat,
            ],
          },
          properties: {
            color: this.getColor(f.types),
            description: f.description,
            name: f.name,
            id: f.id,
            pics: f.pics,
            types: f.types
          },
        };
      });
      return g;
    },

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    findInArea: function () {
      let center = this.map.getCenter();
      let bounds = this.map.getBounds();
      let ne = bounds.getNorthEast();
      let dist = this.getDistanceFromLatLonInKm(
        center.lat,
        center.lng,
        ne.lat,
        ne.lng
      );
      let query = this.geocollection.near({
        center: new firebase.firestore.GeoPoint(center.lat, center.lng),
        radius: dist,
      });
      this.loading = true;
      query.get().then((value) => {
        this.data = value.docs.map((d) => ({ ...d.data(), id: d.id }));
        this.map.getSource("points").setData(this.dataToGeojson(this.data));
        this.$root.$emit('pointsUpdate', this.data);
      }).finally(() => {
        this.loading = false;
      })
    },
    zoomend(e) {
      this.zoom = e.map.getZoom();
    },
    async load(e) {
      this.map = e.map;
     this.map.addImage('check', require('../../assets/check-circle-solid-green.svg'));
      //this.map.setCenter([2.344341187533703, 48.863818070702166]);
      this.map.addControl(
new Mapbox.GeolocateControl({
positionOptions: {
enableHighAccuracy: true
},
trackUserLocation: true
}), 'bottom-right'
);
      if (this.$route.query.lat && this.$route.query.lng) {
        this.map.setCenter([ this.$route.query.lng,  this.$route.query.lat]);
       
      } else {
        try {
           const coordinates = await Geolocation.getCurrentPosition();
          this.map.setCenter([ coordinates.coords.longitude,  coordinates.coords.latitude]);
          this.$route.query.zoom = 15;
        }catch(e) {
          this.map.setCenter([2.1903839876334525, 46.45519808153799]);
        }
       
      }
      if (this.$route.query.zoom) {
        this.map.setZoom(this.$route.query.zoom);
      } else {
          this.map.setZoom(4);
      }
      
      
      const _self = this;
      this.map.on('moveend', function() {
        let center = e.map.getCenter();
        _self.$router.replace({ query: { lat: center.lat, lng: center.lng, zoom: e.map.getZoom() } })
      });
      window.setTimeout(() => {
        this.map.resize();
      }, 500);
      this.map.addSource("points", {
        type: "geojson",
        data: null,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });
    

         this.map.addLayer({
        id: "circles",
        type: "circle",
        source: "points",
        filter: ["has", "point_count"],
        paint: {
// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
// with three steps to implement three types of circles:
//   * Blue, 20px circles when point count is less than 100
//   * Yellow, 30px circles when point count is between 100 and 750
//   * Pink, 40px circles when point count is greater than or equal to 750
'circle-color': [
'step',
['get', 'point_count'],
'#51bbd6',
100,
'#f1f075',
750,
'#f28cb1'
],
'circle-radius': [
'step',
['get', 'point_count'],
20,
100,
30,
750,
40
]
}
      });

        this.map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "points",
        filter: ["has", "point_count"],
    layout: {
'text-field': '{point_count_abbreviated}',
'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
'text-size': 12
}
      });




    this.map.addLayer({
id: 'unclustered-point',
type: 'circle',
source: 'points',
filter: ['!', ['has', 'point_count']],
paint: {
'circle-color': ['get', 'color'],
'circle-radius': 8,
'circle-stroke-width': 1,
'circle-stroke-color': '#fff'
}
});

      /*setTimeout(() => {
        e.map.resize();
      }, 1200);*/
    const self = this;
    this.map.on('click', 'unclustered-point', function (e) {
        self.$root.$emit('open-small-dialog', e.features[0].properties);
    });


    },
  },
  async mounted() {
    this.firestore = firebase.firestore();
    this.geofirestore = geofirestore.initializeApp(this.firestore);
    this.geocollection = this.geofirestore.collection("points");
    let route = this.$route;
    if (route.params) {
         console.log(route.params);
      if (route.params.point) {
       
        let u =  (route.params.locale  ?? 'fr') + '/'+ route.params.region+'/'+route.params.departement+'/'+route.params.city+'/'+route.params.point;
              console.log(u);
              let res;
              if (route.params.locale == 'en') {
                 res = await this.firestore.collection('points').where('slugEN', '==', u).get();
              } else {
                res = await this.firestore.collection('points').where('slugFR', '==', u).get();
              }
            
             if (res.size == 1) {
               let p = res.docs[0];
               if (p.exists) {
                 let d = p.data();
                 if (d.location && d.location.geolocation) {
                   
                   //this.map.setCenter([d.location.geolocation.lng, d.location.geolocation.lat]);
                 }  
               
                  this.$root.$emit('open-dialog-point', {id: p.ref.id, ...d});
               }
             } else if (res.size > 1) {
               console.log('plusieurs documents');
             }
      }
      if (route.params.region) {
        console.log(route.params.region);
      }
    }
  },
  created() {
    // We need to set mapbox-gl library here in order to use it in template
    this.mapbox = Mapbox;
    this.options.mapboxgl = Mapbox;
    this.options.countries = 'fr';
  },
};
</script>
<style scoped>
.mapboxgl-ctrl-top-right {
  bottom: 0;
} 
.mapboxgl-ctrl-geocoder {
  top: 200px;
  position: fixed !important;
}
.wrapperCentral {
  width: 100%;
  z-index: 200;
  height: 50px;
  text-align: center;
  bottom: 65px;
  position: fixed;
}


#searchCity {
  z-index: 203;
  position: fixed;
  top: 200px;
}
.mapbox-container {
  width: 100%;
  height: calc(100% - 40px);
}

</style>