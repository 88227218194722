<template>
    <v-dialog fullscreen v-model="dialog">
        <template v-if="data && !loading">
        <v-card >
<v-carousel v-if="data &&  data.pics.length > 0" hide-delimiters v-model="carrou" height="250" :show-arrows="data.pics.length > 1">
<v-carousel-item
      v-for="(pic, i) in  data.pics"
      :key="'pic_'+i"
       :src="pic.url"
        :lazy-src="pic.url"
       
    >

</v-carousel-item>

</v-carousel>
            <v-card-title class="flex-nowrap">
                <h3 class="">{{ data.name.fr }}</h3>
                <v-spacer/>
                <v-btn icon @click="dialog = false">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle v-if="data.location">
                <span class="text-capitalize"  v-if="data.location.address.city">{{ data.location.address.city }}</span>
                <span class="text-capitalize"  v-if="data.location.address.department && data.location.address.city!==data.location.address.department"> &bullet; {{ data.location.address.city }}</span>
                <span class="text-capitalize"  v-if="data.location.address.region "> &bullet; {{ data.location.address.region }}</span>
                <span class="text-capitalize" v-if="data.location.address.country"> &bullet; {{ data.location.address.country}}</span>
                
            </v-card-subtitle>
            <v-card-text :style="{'padding-bottom':'45px'}">
                <template v-if="data.description.fr">
                     <v-subheader class="text-capitalize">
                    {{ $t('detail.description') }}
                </v-subheader>
                <p>
                    {{ data.description.fr }} 
                </p>
                </template>
                <template v-else>
                   <p> {{ $t('detail.noDescription') }} <v-icon class="ml-3">mdi-emoticon-sad-outline</v-icon></p>
                </template>

                <template>
                    <v-divider />
                    
                    <v-subheader class="text-capitalize"><v-icon class="mr-4">mdi-face-mask-outline</v-icon>{{ $t('detail.covidInfo') }}</v-subheader>
                    <p>{{$t('detail.covidText') }}</p>
                    <template v-for="x of ['COVID19InOperationConfirmed', 'COVID19OpeningPeriodsConfirmed']">
                                            <p :key="'covid_'+x">{{ $t('detail.'+x+'') }} : {{ $t('ui.'+data[x]+'') }}</p>

                    </template>
                </template>
                <template v-if="data.location.address">
                    <v-divider />
                     <v-subheader class="text-capitalize"><v-icon class="mr-4">mdi-map-marker</v-icon>{{ $t('detail.location') }}</v-subheader>
                   <p> {{ address }}</p>
                   <v-row  class="ma-3" :style="{'justify-content':'center'}">
                                           <v-btn   v-if="data.location.geolocation.lng && data.location.geolocation.lat" small outlined  target="_blank" :href="'https://www.google.com/maps/search/?api=1&query='+data.location.geolocation.lat+','+data.location.geolocation.lng+''"><v-icon class="mr-4">mdi-navigation  mdi-rotate-45</v-icon> {{ $t('detail.goto') }}</v-btn>

                   </v-row>
                </template>
                <template v-if="data.contacts">
                    <v-divider />
                     <v-subheader class="text-capitalize"><v-icon class="mr-4">mdi-account-box</v-icon>{{ $t('detail.contacts') }}</v-subheader>
                    <v-row  class="ma-3" :style="{'justify-content':'center'}">
                                           <v-btn @click="sheet = true;" small outlined>{{ $t('detail.contactAction') }}</v-btn>

                   </v-row>
                </template>
               <!-- <template >
     <v-divider />
      <v-subheader class="text-capitalize"><v-icon class="mr-4">mdi-map-marker-radius-outline</v-icon>{{ $t('detail.aroundLocation') }}</v-subheader>
     <Around v-if="data && data.id" :geolocation="data.location.geolocation"  :id="data.id"/>
    
 </template>-->
 <template v-if="data.creator">
     <v-divider />
      <v-subheader class="text-capitalize">{{ $t('detail.creatorTitle') }}</v-subheader>
      <Agent :data="data.creator" />
    
 </template>

 <template v-if="data.publisher">
       <v-divider />
      <v-subheader class="text-capitalize">{{ $t('detail.publisherTitle') }}</v-subheader>
       <Agent :data="data.publisher" />
 </template>

                <template v-if="data.datatourismeProperties">
                     <v-divider />
                       <v-subheader class="text-capitalize"><v-icon class="mr-4">mdi-information</v-icon>Datatourisme</v-subheader>
                       <v-list>
                           <v-list-item>
                               <v-list-item-content>
                                   <v-list-item-title>
                                       {{ $t('detail.datatourismeLastUpdate') }}
                                   </v-list-item-title>
                                   <v-list-item-subtitle>
                                       {{ data.datatourismeProperties.lastUpdate | formatDate }} / {{ data.datatourismeProperties.lastUpdateDatatourisme | formatDate}}
                                   </v-list-item-subtitle>
                               </v-list-item-content>
                           </v-list-item>
                            <v-list-item>
                               <v-list-item-content>
                                   <v-list-item-title>
                                       {{ $t('detail.datatourismeLink') }}
                                   </v-list-item-title>
                                   <v-list-item-subtitle>
                                       <a v-if="data.datatourismeProperties.id" :href="data.datatourismeProperties.id" target="_blank">{{ data.datatourismeProperties.id }}</a>
                                   </v-list-item-subtitle>
                               </v-list-item-content>
                           </v-list-item>
                            <v-list-item>
                               <v-list-item-content>
                                   <v-list-item-title>
                                       {{ $t('detail.datatourismeBatch') }}
                                   </v-list-item-title>
                                   <v-list-item-subtitle>
                                       <span v-if="data.datatourismeProperties.batch">{{ data.datatourismeProperties.batch }}</span>
                                   </v-list-item-subtitle>
                               </v-list-item-content>
                           </v-list-item>
                       </v-list>
                       <p>{{ $t('detail.datatourismeInfo') }} </p>
                </template>
               
               
            </v-card-text>
             <v-bottom-sheet v-model="sheet" >
       <v-card rounded v-if="data.contacts">
         <v-card-title>
           <h3>{{ $t('detail.contactTitle') }}</h3>
           <v-spacer />
            <v-btn icon @click="sheet = false;"><v-icon>mdi-close</v-icon></v-btn>
         </v-card-title>
        <v-card-text>
            <v-list dense> 
            <template v-for="(cat,i) of data.contacts">
                <v-subheader :style="{'height':'20px'}" :key="'title_'+i">{{ $t('catContact.'+i+'') }}</v-subheader>
                <template v-for="(record, ir) of cat">
                    <v-list-item :key="'list_'+ir">
                        <v-list-item-content>
                             <v-list-item-title>
                                <a target="_blank" :href="href(record, i)"> {{ record }}</a>
                             </v-list-item-title>
                        </v-list-item-content>
                       
                    </v-list-item>
                </template>

            </template>
            </v-list>

        </v-card-text>
     
       </v-card>
            

   </v-bottom-sheet>
        </v-card>
        <v-btn  class="pa-3" @click="$store.getters.user.logged ? $root.$emit('game', data): $root.$emit('login', {'redirect': {'action': 'game', 'props': {'point': data}} }) " rounded color="success" fixed bottom>{{ $t('detail.playAndWin') }}</v-btn>
</template>
<template v-else-if="data && !loading">
<p>{{ $t('ui.noPoint') }}</p>
    
</template>
<template v-else>
    <v-progress-circular indeterminate color="teal darken-3" />
</template>
        
    </v-dialog>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Agent from './Agent';
//import Around from './Around';
export default {
    data: () => ({
        carrou: 0,
        dialog: false,
        loading: false,
        data: null,
        sheet: false,
    }),
    components: { Agent /*, Around */},
    created: function() {
         this.$root.$on('open-dialog-point', this.setData);
    },
    filters: {
        formatDate: function(v){
            var [y, m, d] = (new Date(v)).toJSON().slice(0,10).split('-');
            return d + '/' + m + '/'+ y;
        }
    },
    computed: {
        address: function(){
            let address = this.data.location.address;
            return ['streetAddress', 'zipcode', 'city', 'department', 'region'].map((t) => {
                if (address[t]){
                    return address[t];
                }
            }).filter(f => f.length > 0).join(', ');
        }
    },
    methods: {
        href: function(v, type){
            let h = null;
            switch(type){
                case 'websites':
                    h = v;
                    break;
                case 'phones':
                    h = 'tel:'+v;
                    break;
                case 'emails':
                    h = 'mailto:'+v;
                    break;
            }

            return h;
        },
        setData: function (data){
            this.loading = true;
            this.data = data;
            
            this.dialog = true;
            this.db = firebase.firestore();
            let collection = this.db.collection('points');
            collection.doc(data.id ?? data).get().then((d) => {
               console.log(d.exists);
                if (d.exists){
                    
                    this.data = {...d.data(),id: d.id };
                    if (this.data.slugFR) {
                        
                        this.$router.replace({ path: '/'+this.data.slugFR, query: this.$route.query});
                    }
                    //this.$router.replace()
                    document.title = this.data.name.fr +' sur Go-France';


                    let tags = ['meta[name="description"]', 'meta[name="title"]', 'meta[name="og:title"]'];
                    let reg = new RegExp('meta[name="(.*)"]', 'g');

                    for (let tag of tags) {
                        if (document.querySelector(''+tag+'') === null){
                            let r =  reg.exec(tag);
                            console.log(r);
                        }
                    }
                    let desc = this.data.name.fr +' à '+this.data.location.address.city+' ('+this.data.location.address.department+') est sur Go-France. Visitez ce lieu, validez votre présence et remportez '+this.data.values.gps+' points. A vous de jouer !';
                    let desc_meta = document.querySelector('meta[name="description"]');
                    if (desc_meta === null) {
                        desc_meta = document.createElement('meta');
                        desc_meta.name = 'description';

                        document.head.appendChild(desc_meta);
                    }
                    desc_meta.setAttribute("content", desc);

                    let title_meta = document.querySelector('meta[name="title"]');
                    if (title_meta === null) {
                        title_meta = document.createElement('meta');
                        title_meta.name = 'title';

                        document.head.appendChild(title_meta);
                    }
                    title_meta.setAttribute("content", document.title);

                    //let title_og =   document.querySelector('meta[name="og:title"]');


                    // OG PART 









                }
            }).finally(() => {
                this.loading = false;
            })

        }
    }
}
</script>